.work_timeline_card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 1rem;
}

.workmobile_section_container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    margin-top: 2rem;
}

.work_timeline {
    display: flex;
    width: 100%;
}

.work_timeline_card {
    background-color: rgb(77, 77, 77);
}

@media (max-width: 768px) {
    .work_headings {
        width: 90%;
    }

    .work_card_title {
        font-size: 1.25rem;
    }

    .work_card_location {
        font-size: 0.9rem;
    }

    .work_card_description {
        font-size: 1rem;
    }

}