.proj_card {
    padding: 5;
    border-radius: 2rem;
    background-color: rgb(71, 71, 71);
    box-shadow: 0 0 6px 0  #BFAFF2;
    width: 300px;
    height: 380px;
}

.proj_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x:scroll;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
    height: fit-content;
    overflow: visible;
}

.proj_container::-webkit-scrollbar {
    display: none;
  }

.proj_card_inner {
    padding: 4px;
}


.proj_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid rgb(179, 179, 179);
    padding-bottom: 0.5rem;
}

.proj_title > h3 {
    color: rgb(179, 179, 179);
    font-size: 1.2rem;
    font-weight: 600;
}

.proj_description {
    color: rgb(179, 179, 179);
    font-weight: 300;
    padding: 1rem;
    display: flex;
    text-align: left;
    padding-bottom: 0;
}

.proj_tags {
    font-size: 1rem;
    font-weight: 300;
    display: flex;
    justify-content: left;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    color: rgb(194, 114, 190);
    padding: 1rem;
}

.proj_headings {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
    align-items: start;
}

.proj_container_head {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10rem;
    width: 60%;
}

.proj_section_container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    overflow-y: visible;
}

@media (max-width: 768px) {
    .proj_container_head{
        padding: 2rem;
        width: 80%;
    }

    .proj_container {
        justify-content: left;
        margin-top: 0rem;
        padding-left: 2rem;
        padding-top: 4px;
        height: fit-content;
        overflow: auto;
    }

    .proj_card {
        height: fit-content;
    }

}

@media (max-height: 770px) {
    .proj_description {
        font-size: 0.8rem;
    }

    .proj_tags {
        font-size: 0.8rem;
    }

    .proj_title> h3 {
        font-size: 1rem;
    }

}

.proj_placeholder {
    height: 400px;
    width: 400px;
    background-color: transparent;
}

.proj_placeholder > h1 {
    color: transparent;
}