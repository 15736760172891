.cont_section {
    height: fit-content;
    width: 100%;
}

.cont_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-left: 10rem;
    padding-top: 7rem;
    margin-bottom: 1rem;
}

.cont_form {
    display: flex;
    flex: 2;
    width: 100%;
    /* background-color: blue; */
}

.cont_form_content {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px
}

.cont_img {
    flex: 3;
}

.cont_form_content > input, .cont_form_content > textarea {
    padding: 20px;
    background-color: rgb(85, 85, 85);
    border: 1px solid #BFAFF2;
    border-radius: 5px;
    color: rgb(189, 189, 189);
}

.cont_form_content > input:focus, .cont_form_content > textarea:focus {
    outline: none;
}

.cont_form_content > input::placeholder, .cont_form_content > textarea::placeholder {
    color: rgb(187, 187, 187);
    font-size: 1rem;
    font-weight: 300;
}

.cont_form_content > button {
    background-color:  #BFAFF2;
    border-radius: 5px;
    padding: 10px;
    border: none;
    color: rgb(77, 77, 77);
    cursor: pointer;
    font-weight: 500;
}

.cont_message {
    color: #bdbdbd;
    font-weight: 300;
    display: flex;
    justify-content: center;
}

.cont_heading {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.cont_heading_description {
    color: gray;
    font-size: 1.8rem;
    font-weight: 300;
    width: 80%;
}

.cont_text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px
}

.cont_foot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cont_foot_content {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 20px
}

.cont_foot_content > p{
    color: rgb(189, 189, 189);
    font-size: 1.25rem;
    font-weight: 300;
}

.cont_links {
    display: flex;
    align-items: center;
    gap: 15px
}

.cont_rule {
    width: 90%;
    border: none;
    border-top: 1px solid #bdbdbd;
}

@media (max-width: 768px) {
    .cont_container{
        flex-direction: column;
        padding-left: 2.5rem;
        padding-top: 0;
    }

    .cont_img {
        width: 50%;
    }

    .cont_form{
        padding: 0;
        justify-content: start;
    }

    .cont_form_content {
        gap: 20px
    }

    .cont_section {
        padding-top: 10rem;
    }

    .cont_heading_description {
        font-size: 1rem;
    }

    .cont_form_content > input::placeholder, .cont_form_content > textarea::placeholder {
        font-size: 0.8rem;
    }

    .cont_form_content > input, .cont_form_content > textarea {
        padding: 10px;
    }

    .cont_foot_content > p {
        font-size: 1rem;
    }

    .cont_foot_content {
        flex-direction: column;
    }

}

@media (max-width: 400px) {
    .cont_section {
        margin-top: 2rem;
    }
}