.work_section_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.work_container_left {
    flex: 4;
    padding-left: 10rem;
    position: relative;
}

.work_container_right {
    flex: 3;
    padding-right: 5rem;
}

.work_headings {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    margin-bottom: 4rem;
}

.work_card {
    width: 60%;
    height: fit-content;
    background-color: rgb(77, 77, 77);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 0 6px 0  #BFAFF2;
    position: absolute;
}

.work_card_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.work_card_title {
    color: white;
    font-size: 2rem;

}

.work_card_location {
    font-size: 1.25rem;
    color:  #BFAFF2;
    font-weight: 300;
}

.work_card_description {
    color: rgb(190, 190, 190);
    font-weight: 300;
}

.work_card_date {
    font-size: 1rem;
    color:  #BFAFF2;
    font-weight: 400;
}

.work_card_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.work_card_location_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

@media (max-width: 768px) {
    .work_section_container {
        height: fit-content;
    }
}